import { Container, FormControl, InputLabel, Link, MenuItem, Select, Typography } from "@mui/material";
import React, { useState } from "react";
import { connect,useDispatch } from "react-redux";
import MDBox from "../components/MDBox";
import MDInput from "../components/MDInput";

// function helper
import { handleInputChange } from "../components/utilities/HandleChange";

// assets
import download from "../assets/images/upload.png";
import LbicButton from "../components/lbic_component/LbicButton";
import { height } from "@mui/system";
import { ClientCall } from "../context/allotees/apiClass";

const AlloteeProfile = ({currentUser}) => {
    const [alloteeProfile, setAlloteeProfile] = useState(currentUser)
    const [editProfil, setEditProfile] = useState(false)
    const [passport, setPassport] = useState();
    const classes = "black-deep text-center";

    const dispatch = useDispatch();

    const createAllotteeAccount = () => {
        // let formdata = new FormData();
        // for (const key in allottee) {
        //   formdata.append(key, allottee[key]);
        // }
        // formdata.append("password_confirmation", allottee.password);
        // setLoading(true);
        ClientCall(alloteeProfile, "post", `/update-user/${currentUser.id}`, 'update-user', callBack, errCallback,dispatch,'allotee');
      };

      const callBack = (response) => {
        console.log(response.message)
        // console.log(response['0'])
        // setLoading(false);
        // setMessage(response.message)
        // setOpen(true)
        // setErr(false)
        // setErrMessage('')
      };
      const errCallback = (response) => {
        console.log(response.message)
        // setLoading(false);
        // if (response.response.status === 422 ){
        //   setErrMessage(response.response.data.message)
        // }if(response.response.status === 500){
        //   setErrMessage("All field is required")
        // }
        // setErr(true)
      };

    return(
        <Container maxWidth="xl" className="h-100">
            <MDBox role="form" className="h-100 ">
            <div className="row mt-lg-5 mt-md-5 mt-sm-3">
              <MDBox className="item">
                <MDBox mb={3}>
                  <MDInput
                    type="text"
                    label="Owner's Name"
                    variant="standard"
                    fullWidth
                    onChange={(e) => handleInputChange(e, setAlloteeProfile, alloteeProfile)}
                    name="owners_name"
                    defaultValue={currentUser.owners_name}
                  />
                </MDBox>
                <MDBox mb={3}>
                  <MDInput
                    type="text"
                    label="Occupier's Name"
                    variant="standard"
                    fullWidth
                    onChange={(e) => handleInputChange(e, setAlloteeProfile, alloteeProfile)}
                    name="occupiers_name"
                    defaultValue={currentUser.occupiers_name}
                  />
                </MDBox>
                <MDBox mb={3}>
                  <MDInput
                    type="email"
                    label="E-mail Address"
                    variant="standard"
                    fullWidth
                    onChange={(e) => handleInputChange(e, setAlloteeProfile, alloteeProfile)}
                    name="email"
                    defaultValue={currentUser.email}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="phone"
                    label="Phone Number"
                    variant="standard"
                    fullWidth
                    onChange={(e) => handleInputChange(e,setAlloteeProfile, alloteeProfile)}
                    name="phone"
                    defaultValue={currentUser.phone}
                  />
                </MDBox>
        
                <MDBox mb={4}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel
                      id="demo-simple-select-label"
                      className="select-label"
                    >
                      Location
                    </InputLabel>
                    <Select
                      label="Location"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className="select-form-control"
                      onChange={(e) => handleInputChange(e,setAlloteeProfile, alloteeProfile)}
                      name="location"
                      defaultValue={currentUser.location}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="meiran">Meiran</MenuItem>
                      <MenuItem value="ipaja">Ipaja</MenuItem>
                      <MenuItem value="ikorodu">Ikorodu</MenuItem>
                      <MenuItem value="surulere">Surulere</MenuItem>
                    </Select>
                  </FormControl>
                </MDBox>
                <MDBox mb={3}>
                  <MDInput
                    type="number"
                    label="Block Number"
                    variant="standard"
                    fullWidth
                    onChange={(e) => handleInputChange(e,setAlloteeProfile, alloteeProfile)}
                    name="block_number"
                    defaultValue={currentUser.block_number}
                  />
                </MDBox>
              </MDBox>
              <MDBox className="item">
                <MDBox mb={3}>
                  <MDInput
                    type="text"
                    label="Flat Number"
                    variant="standard"
                    fullWidth
                    onChange={(e) => handleInputChange(e,setAlloteeProfile, alloteeProfile)}
                    name="flat_number"
                    defaultValue={currentUser.flat_number}
                  />
                </MDBox>
                {/* <MDBox mb={2}>
                  <MDInput
                    type="password"
                    label="Password"
                    variant="standard"
                    fullWidth
                    onChange={(e) => handleInputChange(e,setAlloteeProfile, alloteeProfile)}
                    name="password"
                    defaultValue={currentUser.flat_number}
                  />
                </MDBox> */}
                <MDBox  py={3} px={3}>
                  {currentUser.img !== null ? (
                    <MDBox style={{ marginRight: "2px", padding: "5px 2px" }}>
                      <img src={currentUser.img} style={{width: '50%'}} />
                    </MDBox>
                  ) : null}
                  <MDBox>
                    <MDBox className="upload-holder d-flex flex-column align-items-center">
                      {/* <img src={download} />
                      <MDInput
                        type="file"
                        label="File"
                        variant="standard"
                        fullWidth
                        className="upload-btn"
                        name="img"
                        onChange={(e) => handleInputChange(e, "file")}
                      /> */}
                    </MDBox>
                    {/* <MDBox>
                      <Typography
                        className={
                          passport
                            ? "small-text " + classes
                            : "medium-text " + classes
                        }
                      >
                        Browse File to upload
                      </Typography>
                      <Typography className="small-text black-medium text-center">
                        File Supported: PNG,JPEG,JPG
                      </Typography>
                    </MDBox> */}
                  </MDBox>
                </MDBox>
                <LbicButton
                  onClick={createAllotteeAccount}
                  title="Submit"
                  color="success"
                  className='button-center'
                />
              </MDBox>
            </div>
          </MDBox>
        </Container>
    )
}

export default connect(state => ({currentUser: state.alloteeReducer.currentUser}))(AlloteeProfile)