// dependency
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";

// components mui
import {
  Typography,
  Container,
  Box,
  Grid,
  Card,
  styled,
  Paper,
} from "@mui/material";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import HomeIcon from "@mui/icons-material/Home";

// CSS
import "../assets/CSS/index.css";
import { PaystackInstance } from "../components/utilities/Paystack";
import { ClientCall } from "../context/allotees/apiClass";
import house from "../assets/landscape.jpg";

// custom styled component
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));


const CoverList = ({ currentUser }) => {
  const [clicked, setClicked] = useState("");
  const [premiumCover, setPremiumCover] = useState([]);

  useEffect(() => {
    ClientCall(
      null,
      "get",
      "/getCover",
      null,
      callback,
      errCallback,
      null,
      "allotee"
    );
  }, []);

  const callback = (response) => {
    // toast('Successful Request')
    // navigate('/dashboard')
    setPremiumCover(response.plan);
  };

  const errCallback = (response) => {
    // setLoading(false)
    // setErr(true)
    // if(response.response.status === 422){
    //   setMessage(response.response.data.error)
    // }if(response.response.status === 401 || 500){
    //   setMessage("User does not exist in our system");
    // }
  };

  const displayDropdown = (event) => {
    const idClicked = event.target.parentElement.id;
    let els = document.getElementsByClassName("dropdown");
    for (let el = 0; el < els.length; el++) {
      const ele = els[el].classList.contains("active-drop");
      const elsId = els[el].getAttribute("id");
      let table = els[el].children[1].children[0];
      if (elsId === idClicked && !ele) {
        table.classList.add("active-drop");
        table.classList.remove("close-activedrop");
        els[el].classList.add("active-drop");
        els[el].classList.remove("close-activedrop");
        setClicked(elsId);
        console.log(ele);
        if (ele && elsId === idClicked) {
          setClicked("");
        }
      } else {
        els[el].classList.remove("active-drop");
        els[el].classList.add("close-activedrop");
        table.classList.remove("active-drop");
        table.classList.add("close-activedrop");
        console.log(ele);
      }
    }
  };

  const CoverItem = (props) => {
    return (
      <Grid item xl={3} xs={12} sm={5} lg={3}>
        <Card>
          <CardMedia
            style={{
              margin: 0,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
            component="img"
            height="250"
            image={house}
          />
          <CardContent
            onMouseEnter={(el) =>
              el.currentTarget.children.item(2).classList.add("hover")
            }
            onMouseLeave={(el) =>
              el.currentTarget.children.item(2).classList.remove("hover")
            }
          >
            <Typography gutterBottom variant="h5" component="div">
              <HomeIcon fontSize="small" /> {props.cover.cover_type}
            </Typography>
            <Box
              marginBottom={0}
              paddingBottom={0}
              className="d-flex flex-row justify-content-between flex-wrap"
            >
              <Typography variant="h6" component="div">
                {props.cover.cover_flat}
              </Typography>
              <Typography variant="h6" component="div">
                Value: 5M
              </Typography>
            </Box>
            <Box className="dropdown" id={props.cover_key}>
              <Box className="me-sm-auto details">
                <Typography className="small-text bold">
                  WHAT THE INSURANCE COVERS
                </Typography>
                <Box>
                  <Typography
                    variant="h5"
                    fontWeight={700}
                    fontSize={16}
                    color="#000000"
                  >
                    Repairs and Reinstatement
                  </Typography>
                  <Typography
                    variant="h5"
                    fontWeight={700}
                    fontSize={14}
                    color="#000000"
                    component="div"
                  >
                    Arise From:
                    <Typography variant="p" component="ul">
                      <Typography
                        variant="p"
                        fontWeight={500}
                        fontSize={14}
                        component="li"
                      >
                        Fire
                      </Typography>
                      <Typography
                        variant="p"
                        fontWeight={500}
                        fontSize={14}
                        component="li"
                      >
                        Explosion
                      </Typography>
                      <Typography
                        variant="p"
                        fontWeight={500}
                        fontSize={14}
                        component="li"
                      >
                        Flood
                      </Typography>
                      <Typography
                        variant="p"
                        fontWeight={500}
                        fontSize={14}
                        component="li"
                      >
                        Storm
                      </Typography>
                      <Typography
                        variant="p"
                        fontWeight={500}
                        fontSize={14}
                        component="li"
                      >
                        Earthquake
                      </Typography>
                    </Typography>
                  </Typography>
                  <Typography
                    textAlign={"center"}
                    fontWeight="500"
                    paddingTop={"20px"}
                  >
                    TO THE BUILDING
                  </Typography>
                </Box>
              </Box>
            </Box>
          </CardContent>
          <CardActions className="d-flex flex-row justify-content-between flex-wrap">
            <PaystackInstance
              cover_id={props.cover.id}
              email={currentUser.email}
              amount={props.cover.cover_price}
            />
            <Box className="d-flex flex-row justify-content-end align-items-center">
              <img width={25} height={25} src={require('../assets/images/naira.png')} />
              <Typography
                width={"100%"}
                textAlign="right"
                variant="h3"
                component="div"
                color={'#000000'}
              >
                {props.cover.cover_price}
              </Typography>
            </Box>
          </CardActions>
        </Card>
      </Grid>
    );
  };

  // const responsiveGap = () => {
  //   var newWidth = window.innerWidth;
  //   window.addEventListener('resize', function(event){
  //     newWidth = window.innerWidth;
  //   })

  //   console.log(newWidth)

  //   if(newWidth <= 900){
  //     return 8
  //   }
  // }

  return (
    <Container className="container-dashboard">
      <Box className="mb-sm-5"></Box>
      <Grid container gap={8} 
      >
        {premiumCover.length > 0 ? (
          premiumCover.map((item, i) => (
            <CoverItem key={i} cover_key={i + 1} cover={item} />
          ))
        ) : (
          <>
            <p>No cover's</p>
          </>
        )}
      </Grid>
    </Container>
  );
};

export default connect((state) => {
  return { currentUser: state.alloteeReducer.currentUser };
})(CoverList);
