// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Menu from "@mui/material/Menu";

// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox";

// Material Dashboard 2 React example components
import DefaultNavbarLink from "./DefaultNavbarLink";

// CSS
import '../../../assets/CSS/index.css'

function DefaultNavbarMobile({ open, close }) {
  const { width } = open && open.getBoundingClientRect();
  return (
    <Menu
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      anchorEl={open}
      open={Boolean(open)}
      onClose={close}
      MenuListProps={{ style: { width: `calc(${width}px - 4rem)`, textAlign: 'right'} }}
      className='outer_mobile_menu'
    >
      <MDBox px={0.5}>
        <DefaultNavbarLink style={{padding: '15px 5px 15px'}} icon="donut_large" name="home" route="/" className='menuitem' />
        <DefaultNavbarLink style={{padding: '15px 5px 15px'}} icon="account_circle" className='menuitem' name="register" route="/authentication/register" />
        <DefaultNavbarLink style={{padding: '15px 5px 15px'}} icon="key" name="login" className='menuitem' route="/authentication/login" />
      </MDBox>
    </Menu>
  );
}

// Typechecking props for the DefaultNavbarMenu
DefaultNavbarMobile.propTypes = {
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.object]).isRequired,
};

export default DefaultNavbarMobile;
