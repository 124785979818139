import api from "../../context/axiosInstance";
import {
  SIGNUP_SUCCESSFUL,
  SIGNIN_SUCCESSFUL,
  SIGNUP_FAILURE,
  PURCHASE_COVER,
  ALLOTTEE_SIGNOUT,
  UPDATE_ALLOTTEE,
  ADD_PROPERTY,
  REMOVE_PROPERTY
} from "./actionTypes";
import Store from "../Store";

let properties = Store.store.getState().alloteeReducer.properties;

const signInSuccess = (data) => ({
  type: SIGNIN_SUCCESSFUL,
  payload: {
    currentUser: data.user,
    isLoggedIn: true,
    token: data.access_token,
  },
});

export const signOut = () => ({
  type: ALLOTTEE_SIGNOUT,
  payload: { isLoggedIn: false },
});

const updateAllotee = (data) => ({
  type: UPDATE_ALLOTTEE,
  payload: { currentUser: data },
});

export const addProperty = (data) => ({
  type: ADD_PROPERTY,
  payload: { property: data },
});

const removeProperty = () => ({
  type: REMOVE_PROPERTY,
  payload: {property : []}
})

// const reduxActions =

export const ClientCall = async (
  data,
  type,
  endurl,
  actiontype,
  callback,
  errcallback,
  dispatch,
  userType,
  propertyLength
) => {
  switch (type) {
    case "post":
      await api
        .post(`${userType !== null ? userType : "api"}/auth${endurl}`, data)
        .then((response) => response.data)
        .then((data) => {
          if (actiontype === "login") {
            dispatch(signInSuccess(data));
          }
          if (actiontype === "update-user") {
            dispatch(updateAllotee(data["0"]));
          }
          if (actiontype === "add-property") {
            dispatch(addProperty(data["0"]));
          }
          callback(data);
        })
        .catch((e) => errcallback(e));
      break;
    case "get":
      await api
        .get(`${userType !== null ? userType : "api"}/auth${endurl}`)
        .then((response) => response.data)
        .then((data) => {
            if (actiontype === "add-property"){
              if(Object.keys(data.Transactions).length > propertyLength) {
                console.log(Object.keys(data.Transactions).length+" is greater than "+ properties.length)
                dispatch(removeProperty())
                for (const key in data.Transactions) {
                  dispatch(addProperty(data.Transactions[key]))
                }
            }
          }
          callback(data);
        })
        .catch((e) => errcallback(e));
      break;
    case "put":
      await api
        .put(`${userType !== null ? userType : "api"}/auth${endurl}`)
        .then((response) => response.data)
        .then((data) => {
          callback(data);
        })
        .catch((e) => errcallback(e));
      break;
    case "delete":
      await api
        .get(`${userType !== null ? userType : "api"}/auth${endurl}`)
        .then((response) => response.data)
        .then((data) => callback(data))
        .catch((e) => errcallback(e));
      break;
    default:
      break;
  }
};
