const allotee_initial_state = {
  currentUser: null,
  properties: [],
  isLoggedIn: false,
  token: null
};

export const alloteeReducer = (state = allotee_initial_state, action) => {
  switch (action.type) {
    case "SIGNIN_SUCCESSFUL":
      return {
        ...state,
        currentUser: action.payload.currentUser,
        isLoggedIn: action.payload.isLoggedIn,
        token: action.payload.token
      };
    case "ALLOTTEE_SIGNOUT":
      return {...state, isLoggedIn: action.payload.isLoggedIn, currentUser: null, properties: []};
    case "UPDATE_ALLOTTEE":
      return {
        ...state,
         currentUser: action.payload.currentUser,
      }
    case "ADD_PROPERTY":
      return {
        ...state, properties: [...state.properties,action.payload.property]
      }
    case "REMOVE_PROPERTY":
      return {
        ...state, properties: action.payload.property
      }
    default:
      return state;
  }
};
