import { Container } from "@mui/material";
import React from "react";

// CSS
import '../assets/CSS/index.css'


// components
import Header from "../components/lbic_component/Header";

import DefaultNavbar from "../examples/Navbars/DefaultNavbar/Navigation";


const HomePage = () => {
  return (
    <Container className='container'>
      <DefaultNavbar  />
      <Header />
    </Container >
  );
};

export default HomePage;
