
import { useNavigate } from 'react-router-dom';

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "../components/MDBox";

// dependencies
import { useEffect, useState } from "react";
// import { toast } from "react-toastify";

// Material Dashboard 2 React example components
import DashboardLayout from "../examples/LayoutContainers/DashboardLayout";
import { Container, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

// utilities
import VisibilityIcon from "@mui/icons-material/Visibility";
import ReceiptIcon from '@mui/icons-material/Receipt';
import Pagination from "../components/lbic_component/Pagination";
import BasicTable from "../components/lbic_component/Table";

// css
import "../assets/CSS/index.css";
import { ClientCall } from "../context/allotees/apiClass";
import ModalMessenger from "../components/lbic_component/Modal";
import { Navigate } from "react-router-dom";
import Invoice from './Invoice';

const ButtonDisplayPayment = () => {
  return (
    <span className="actionbtn">
      <ReceiptIcon fontSize="20"/>
    </span>
  );
};

const ModalBody = ({ payment }) => {
  // let dataArr = []
  // let dataobject={}
  const PaymentValues = () => {
    return (
      <div style={{ marginTop: "20px" }}>
        <div className="row" style={{ width: "fit-content" }}>
          <Typography fontSize={15} color="GrayText" width={"150px"}>
            Cover Price:
          </Typography>
          <Typography fontSize={15} color="InfoText" width={"150px"}>
            {Number(payment.userId) * 1000}
          </Typography>
        </div>
        <div className="row" style={{ width: "fit-content" }}>
          <Typography fontSize={15} color="GrayText" width={"150px"}>
            Cover Plan:
          </Typography>
          <Typography fontSize={15} color="InfoText" width={"150px"}>
            Bungalow
          </Typography>
        </div>
        <div className="row" style={{ width: "fit-content" }}>
          <Typography fontSize={15} color="GrayText" width={"150px"}>
            Premium Start Date:
          </Typography>
          <Typography fontSize={15} color="InfoText" width={"150px"}>
            10th April 2022
          </Typography>
        </div>
        <div className="row" style={{ width: "fit-content" }}>
          <Typography fontSize={15} color="GrayText" width={"150px"}>
            Premium Expires on:
          </Typography>
          <Typography fontSize={15} color="InfoText" width={"150px"}>
            10th April 2023
          </Typography>
        </div>
      </div>
    );
    // }
  };
  return (
    <>
      <div className="row">
        <Typography color="InfoText" width={"fit-content"}>
          Payment Details
        </Typography>{" "}
        <span
          className="row"
          style={{ width: "fit-content", alignItems: "center" }}
        >
          <Typography
            component={"p"}
            color='GrayText'
            width={"fit-content"}
            fontSize="15px"
            paddingRight={1}
          >
            Paid On:
          </Typography>
          <Typography
            component={"p"}
            color="InfoText"
            width={"fit-content"}
            fontSize="15px"
            paddingLeft={0}
          >
            10-04-2022
          </Typography>
        </span>
      </div>
      <div>
        <PaymentValues />
      </div>
    </>
  );
};

function PremiumPaymentsHistory({ admin }) {
  const [premiumPayments, setPremiumPayments] = useState([]);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [show, setShow] = useState(false);
  const [onePremData, setOnePremData] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    ClientCall(null,'get','/showAlloteeTransaction',null,callback,errCallback,'null','allotee')
    // fetch("https://jsonplaceholder.typicode.com/todos")
    //   .then((res) => res.json())
    //   .then((res) => setPremiumPayments(res))
    //   .catch((e) => console.log(e));
  }, []);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  let currentDatas = premiumPayments.length > 0 ? premiumPayments.slice(indexOfFirstData, indexOfLastData): []

  const callback = (response) => {
    let transaction = {};
    let transArr = []
    for (const key in transaction = response.Transactions) {
      transArr.push(transaction[key])
      if(Object.keys(transaction).length === transArr.length){
        setPremiumPayments(transArr);
      }
    }
  };

  const errCallback = () => {
    console.log("Unsuccessful Request");
  };

  // testion method passed as props to table
  const sayIdNumber = (d) => {
    alert(d);
  };

  // Function close modal when open
  const handleClose = () => setShow(false);

  const displayPremPayment = (props) => {
    setShow(true);
    setOnePremData(props);
    // console.log(props)
    // navigate(`/payment-reciept/:reciept=${Number(props.id)}`)
  };


  return (
    <Container className="container-dashboard">
      <ModalMessenger
        open={show}
        handleClose={handleClose}
        body={<Invoice payment={onePremData}/>}
        width="lg"
      />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Pagination
              dataPerPage={dataPerPage}
              totalData={premiumPayments.length}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              range={3}
            />
            {/* <MDBox mb={1.5}>
              <Typography>All Registered Allotees</Typography>
            </MDBox> */}
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <BasicTable
            style={{ width: "100%" }}
            heading={[
              "Cover Plan",
              "Cover Price",
              "Start Date",
              "Due Date",
              "Date Paid",
              "Actions",
            ]}
            values={["coverFlat", "id", "start_date", "expire_date", "created_at"]}
            data={currentDatas}
            color="#343a40"
            actionMethod={sayIdNumber}
            doAction={displayPremPayment}
            actionComponent={
              <ButtonDisplayPayment setShow={setShow} data={currentDatas} />
            }
          />
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}></Grid>
            <Grid item xs={12} md={6} lg={4}></Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Container>
  );
}

export default PremiumPaymentsHistory;
