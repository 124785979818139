import React from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import Sidenav from "../examples/Sidenav";
import routes from "../Routes/routes";
import DashboardLayout from "../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../examples/Navbars/DashboardNavbar";
import { SessionTimer } from "../components/utilities/helperFunctions";
import SessionTimedout from "../components/lbic_component/SessionTimedout";
import ResponsiveMenu from "../components/lbic_component/ResponsiveMenu";


export const NoAuthLayout = connect((state) => {
  return {isLoggedIn: state.alloteeReducer.isLoggedIn};
})(({children,isLoggedIn}) => {
  if (!isLoggedIn) {
    return <>{children}</>;
  } else {
    return <Navigate to='/dashboard'/>
  }
});

export const AuthLayout = connect((state) => {
  return {isLoggedIn: state.alloteeReducer.isLoggedIn, currentUser: state.alloteeReducer.currentUser};
})(({children, isLoggedIn,currentUser}) => {
  const [isTimeout, setisTimeout] = React.useState(false);
  let idleTimer;
  React.useEffect(() => {
    idleTimer = new SessionTimer({
      timeout: 360,
      onTimeout: () => setisTimeout(true),
      onExpired: () => setisTimeout(true),
    });
    return () => {
      idleTimer.cleanUp();
    };
  }, []);
  if (isLoggedIn) {
    if(isTimeout){
      return <SessionTimedout isTimeout={isTimeout} />
    }
    return (
      <DashboardLayout>
        <DashboardNavbar static isMini />
        <ResponsiveMenu routes={routes} />
        <Sidenav className="sidenav" color="success" routes={routes} brandName={currentUser.owners_name}/>
        {children}
      </DashboardLayout>
    );
  } else {
    return <Navigate to='/' />
  }
  
});
