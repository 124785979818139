// @mui icons
import Icon from "@mui/material/Icon";

// Auth layouts
import { AuthLayout, NoAuthLayout } from "../layout";

// page component
import HomePage from "../pages/Home";
import SignIn from "../pages/SignIn";
import Register from "../pages/Register";
import AllotteeDashboard from "../pages/AlloteeDashboard";
import CoverList from "../pages/CoverList";
import MakePayment from "../pages/MakePayment";
import Invoice from "../pages/Invoice";
import PremiumPaymentsHistory from "../pages/PremiumPaymentsHistory";
import Profile from "../pages/Profile";
import ClaimReport from "../pages/ClaimReport";
import ViewProperties from "../pages/ViewProperties";

const routes = [
  {
    name: "Home",
    key: "home",
    route: "/",
    component: <NoAuthLayout children={<HomePage />} />
  },
  {
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/login",
    component: <NoAuthLayout children={<SignIn />} />,
  },
  {
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/register",
    component: <NoAuthLayout children={<Register />} />,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/dashboard",
    responsive: true,
    component: <AuthLayout children={<AllotteeDashboard />} />,
  },
  {
    type: "collapse",
    name: "Buy Cover",
    route: "/insurance-covers",
    key: "insurance-covers",
    responsive: true,
    component: <AuthLayout children={<CoverList />} />,
  
  },
  {
    type: "nocollapse",
    name: "Insurance Payment",
    route: "/insurance-payment",
    key: "insurance-payment",
    component: <AuthLayout children={<MakePayment />} />,
  
  },
  {
    type: "collapse",
    name: "Claim Report",
    route: "/claim-report",
    key: "/claim-report",
    responsive: true,
    component: <AuthLayout children={<ClaimReport/>} />,
  },
  ,
  {
    type: "collapse",
    name: "View Premium History",
    route: "/payment-history",
    key: "payment-history",
    responsive: true,
    component: <AuthLayout children={<PremiumPaymentsHistory />} />,

  },
  {
    type: "collapse",
    name: "View Certificate",
    route: "#",
  },
  {
    type: "nocollapse",
    name: "Payment Reciept",
    route: "/payment-reciept/:reciept",
    key: "payment-reciept",
    responsive: true,
    component: <AuthLayout children={<Invoice />} />,
  },
  {
    type: "collapse",
    name: "Edit Profile",
    route: "/:name",
    responsive: true,
    component: <AuthLayout children={<Profile />} />
  },
  {
    type: "nocollapse",
    name: "View Properties",
    route: "/view-properties",
    component: <AuthLayout children={<ViewProperties/>} />,
    responsive: true,
  }
];

export default routes;
