import * as React from "react";

const Pagination = ({
  dataPerPage,
  totalData,
  currentPage,
  setCurrentPage,
  range,
}) => {
  const totalPageNumbers = Math.round(totalData / dataPerPage)
  const lastPage = totalData / dataPerPage;
  let pageDisplay = []


//   for (let i = 1; i <= Math.round(totalData / dataPerPage); i++) {
//     totalPageNumbers.push(i);
//   }

  const setNextPage = (page) => {
    if (lastPage > currentPage) {
      setCurrentPage(page + 1);
    }
  };


  const setPrevPage = (page) => {
    if (currentPage !== 1 ) {
      setCurrentPage(page - 1);
    }
  };

  const DisplayPage = () => {
    let startIndex = Math.round((dataPerPage * currentPage) / dataPerPage);
    let endIndex = Math.round(
      (dataPerPage * (currentPage + range - 1)) / dataPerPage
    );
    let pages

    for (let i = 1; i <= endIndex; i++) {
        pageDisplay.push(i)
    }
    
    // pages = pageDisplay.length > range ? pageDisplay.slice(currentPage-1,currentPage + range-1) : pageDisplay
    pages = pageDisplay

    if(currentPage < pages[range]){
      pages.slice(0,range)
    }
    // else return pages.slice(currentPage-1,currentPage + range-1)

    return pages;
  };

  const jumpToPage = (num) => {
    setCurrentPage(num);
  };

  return (
    <nav aria-label="...">
      <ul className="pagination justify-content-end">
        <li
          className={`page-item ${currentPage === 1 ? "disabled" : "none"}`}
          onClick={() => setPrevPage(currentPage)}
        >
          <a className="page-link" href="#" tabindex="-1">
            Previous
          </a>
        </li>
        {DisplayPage().map((num) => (
          <li
            className={`page-item ${currentPage === num ? "active" : null}`}
            key={num}
            onClick={() => jumpToPage(num)}
          >
            <a className="page-link" href="#">
              {num}
            </a>
          </li>
        ))}
        {/* <li className="page-item active">
        <a className="page-link" href="#">
          2 <span className="sr-only">(current)</span>
        </a>
      </li>
      <li className="page-item">
        <a className="page-link" href="#">
          3
        </a>
      </li> */}
        <li
          className={`page-item ${
            lastPage === currentPage ? "disabled" : "none"
          }`}
          onClick={() => setNextPage(currentPage)}
        >
          <a className="page-link" href="#">
            Next
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
