// mui component
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Icon } from "@mui/material";

const ModalMessenger = (props) => {
  const { open, handleClose, callback, buttonText, isButton,body,iconName,buttonClasses,width, ...content} = props;
  const defaultButtonDisplay = isButton === undefined || null ? false : isButton

  return (
    <Dialog open={open} onClose={handleClose} {...content} fullWidth={true} maxWidth={width}>
      <DialogContent >
        {body}
      </DialogContent>
      {defaultButtonDisplay ? <DialogActions>
        <Button autoFocus onClick={callback} className={buttonClasses}>
          {/* {iconName !== undefined ? <Icon>{iconName}</Icon> : null} */}
          {buttonText}
        </Button>
      </DialogActions> : null}
    </Dialog>
  );
};

export default ModalMessenger
