import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { signOut } from "../../context/allotees/apiClass";

// menu
import '../../assets/CSS/index.css'
import MDBox from "../MDBox";
import MDButton from "../MDButton";

import { toggleMenu } from "../utilities/helperFunctions";



const ResponsiveMenu = ({routes}) => {
    const dispatch = useDispatch()
    const getMobileRoutes = () => {
        return routes.filter(route => route.responsive)
    }
    return(
        <div className="responsive-menu-off" id='id-mobile-menu'>
            <nav>
                <ul>
                    {getMobileRoutes().map((link) => <li className="mobile-menu-item" onClick={() => toggleMenu()}><Link to={link.route}>{link.name}</Link></li>)}
                </ul>
            <MDBox className="mobile-bottom">
                    <MDButton onClick={() => dispatch(signOut())}>Log Out</MDButton>
                </MDBox>
                {/* <MDBox p={2} mt="auto">
        <MDButton
          component="a"
          target="_blank"
          rel="noreferrer"
          variant="gradient"
        //   color={sidenavColor}
          fullWidth
        //   onClick={() => {
        //     dispatchSignOut(signOut())}}
        >
          Log out
        </MDButton>
      </MDBox> */}
            </nav>
        </div>
    )
}

export default ResponsiveMenu