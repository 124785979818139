import { usePaystackPayment } from "react-paystack";
import MDButton from "../MDButton";
import "../../assets/CSS/index.css";
import { ClientCall } from "../../context/allotees/apiClass";
import { useCallback } from "react";

export const PaystackInstance = ({ email, amount, cover_id }) => {
  // paystack public key component
  const public_key = process.env.REACT_APP_TEST_PUBLIC_KEY;

  const config = {
    reference: new Date().getTime().toString(),
    email: email,
    amount: amount * 100,
    publicKey: public_key,
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const initializePayment = usePaystackPayment(config);

  const makePayment = (key) => {
    const onSuccess = (reference) => {
      // Submit cover plan bought by user/allotee to backend
      console.log(reference)
      ClientCall({cover_id: key, paystack_ref: reference.reference},'post','/subscribeCoverPlan',null,callback,errcallback,null,'/allotee/plan');
    };

    initializePayment(onSuccess, onClose);
  };

  const errcallback = (response) => {
    console.log(response)
  }

  const callback = (response) => {
    console.log(response)
  }
  return (
    <MDButton className="buy-button" onClick={() => makePayment(cover_id)}>
      Buy
    </MDButton>
  );
};
