import * as React from "react";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";

export default function BasicTable(props) {
  const { style, heading, searchInput, data, color,searchCondition, adminType, values,actionComponent,doAction } = props;
  const formatDate = moment;
  let checkValue;
  let vals = []; 

  let tentativeHeading
  if(adminType === 2){
    tentativeHeading = heading.filter(i => i !== 'Actions')
  }else {
    tentativeHeading = heading
  }

  if (heading !== undefined) {
    checkValue = heading.find((key) => key.toLowerCase() === "actions");

    values.forEach((element) => {
      element.includes(" ")
        ? vals.push(element.replace(" ", "_"))
        : vals.push(element);
    });
  }

  const actionsIncluded = vals.includes('actions')
  if(actionsIncluded){vals.pop()}

  const isAction = checkValue === undefined ? false : true;


  // method to sort specific values from object in data array
  const DisplayValues = (props) => {
    const arrayObject = props.val
    let newObject = {}
    for (let v = 0; v < vals.length; v++) {
      const element = vals[v];
      let propval = element !== "created_at" ? arrayObject[element] : formatDate(arrayObject[element]).format("YYYY-MM-DD")
      if(v <= vals.length){
        newObject = {...newObject, [element]: propval}
      }
    }

    for (const key in newObject){
      return Object.values(newObject).map((p) => (<TableCell key={key} align={props.textAlign}>{p}</TableCell>))
    }
  }

  let tentativeArray =
      searchInput !== "" && data.length > 0
        ? data.filter((i) =>
            { if(searchCondition !== undefined){
              for(const s=0; s<searchCondition.length; i++){
              let result = i[searchCondition[s]].toLowerCase().includes(searchInput.search)
              return result !== undefined ? (result) : 'do nothing'
            }}else return data
            }
          )
        : data;

      

  return (
    <TableContainer component={Paper}>
      <Table sx={style} aria-label="simple table">
        <TableHead style={{ display: "table-header-group", background: color }}>
          <TableRow sx={style}>
            {tentativeHeading.map((row, i) => (
              <TableCell
                key={i + 1}
                align={props.textAlign}
                style={{ color: "#ffffff", fontWeight: 500 }}
              >
                {row}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tentativeArray.map((item, i) => (
            <TableRow>
              {/* {console.log()} */}
              <DisplayValues val={item} />
              {isAction ? (
                <TableCell align={props.textAlign}>
                  <div className="actions-container" key={item.id} onClick={() => doAction(item)}>
                    {actionComponent}
                  </div>
                </TableCell>
              ) : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
