import axios from "axios"
import store from './Store'

const instance = axios.create({
    baseURL: 'https://api.allotee.itl.ng'
})

instance.interceptors.request.use(request => {
    const token = store.store.getState().alloteeReducer.token
    const isLoggedIn = store.store.getState().alloteeReducer.isLoggedIn;

    if(isLoggedIn){
        request.headers.Authorization = `Bearer ${token}`
    }

    return request
})

export default instance