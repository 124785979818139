// dependencies
import { useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import { MenuItem, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";

// Material Dashboard 2 React components
import MDBox from "../components/MDBox";
import MDTypography from "../components/MDTypography";

import MDInput from "../components/MDInput";

// Authentication layout components
import CoverLayout from "./authentication/components/CoverLayout";

// Images
import bgImage from "../assets/house.png";
import download from "../assets/images/upload.png";

// css rules
import "../../src/assets/CSS/index.css";
import LbicButton from "../components/lbic_component/LbicButton";
import {ClientCall} from "../context/allotees/apiClass";
import ModalMessenger from "../components/lbic_component/Modal";
import { MessageDisplay } from "../components/lbic_component/Message";
import { useWidth } from "../components/utilities/helperFunctions";

const Register = () => {
  const [allottee, setAllottee] = useState({});
  const [loading, setLoading] = useState(false);
  const [passport, setPassport] = useState();
  const [open,setOpen] = useState(false);
  const [message,setMessage] = useState('Registration Successful');
  const [errMessage, setErrMessage] = useState('')
  const [err,setErr] = useState(false)

  let navigate = useNavigate()
  let width = useWidth(425)

  const handleInputChange = (e, type) => {
    const { name, value } = e.target;
    setAllottee({
      ...allottee,
      [name]: type === "text" ? value : e.target.files[0],
    });
    if (type !== "text") {
      setPassport(URL.createObjectURL(e.target.files[0]));
    }
  };

  const createAllotteeAccount = () => {
    let formdata = new FormData();
    for (const key in allottee) {
      formdata.append(key, allottee[key]);
    }
    formdata.append("password_confirmation", allottee.password);
    setLoading(true);
    ClientCall(formdata, "post", "/register", null, callBack, errCallback,null,null);
  };
  const callBack = (response) => {
    setLoading(false);
    setMessage(response.message)
    setOpen(true)
    setErr(false)
    setErrMessage('')
  };
  const errCallback = (response) => {
    setLoading(false);
    if (response.response.status === 422 ){
      setErrMessage(response.response.data.message)
    }if(response.response.status === 500){
      setErrMessage("All field is required")
    }
    setErr(true)
  };

  const Loading = () => {
    return <div className={
      !loading ?
       "no-loadin-anim"
       : 
       "loading-anim"
      }></div>;
  };
  const classes = "black-deep text-center";

  const handleClose = () => {
    setOpen(false)
  }

  const modalCallBack = () => {
    navigate('/authentication/login')
  }

  return (
    <CoverLayout image={bgImage} urlText="Login" link="/authentication/login">
      <ModalMessenger
        open={open}
        handleClose={handleClose}
        message={message}
        isButton
        buttonText='Login'
        callback={modalCallBack}
        body={() => (<Typography gutterBottom className="normal-text black-medium" >
        {message}
      </Typography>)}
      />
      <Card className="card-width mb-sm-3">
        <Loading />
        <MDBox py={10} px={3}>
          <MessageDisplay open={err} className="text-start small-text" style={{color: 'red' ,marginLeft: '30px'}} message={errMessage} />
          <MDBox role="form">
            <MDBox mx={2} mb={7} className="column">
              <MDBox className="item">
                <MDBox mb={3}>
                  <MDInput
                    type="text"
                    label="Owner's Name"
                    variant="standard"
                    fullWidth
                    onChange={(e) => handleInputChange(e, "text")}
                    name="owners_name"
                  />
                </MDBox>
                <MDBox mb={3}>
                  <MDInput
                    type="text"
                    label="Occupier's Name"
                    variant="standard"
                    fullWidth
                    onChange={(e) => handleInputChange(e, "text")}
                    name="occupiers_name"
                  />
                </MDBox>
                <MDBox mb={3}>
                  <MDInput
                    type="email"
                    label="E-mail Address"
                    variant="standard"
                    fullWidth
                    onChange={(e) => handleInputChange(e, "text")}
                    name="email"
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="phone"
                    label="Phone Number"
                    variant="standard"
                    fullWidth
                    onChange={(e) => handleInputChange(e, "text")}
                    name="phone"
                  />
                </MDBox>
                {!width ? <LbicButton
                  onClick={createAllotteeAccount}
                  title="Submit"
                  color="success"
                /> : null}
              </MDBox>
              <MDBox className="item">
                <MDBox mb={4}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel
                      id="demo-simple-select-label"
                      className="select-label"
                    >
                      Location
                    </InputLabel>
                    <Select
                      label="Location"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className="select-form-control"
                      onChange={(e) => handleInputChange(e, "text")}
                      name="location"
                      value={allottee.location}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="meiran">Meiran</MenuItem>
                      <MenuItem value="ipaja">Ipaja</MenuItem>
                      <MenuItem value="ikorodu">Ikorodu</MenuItem>
                      <MenuItem value="surulere">Surulere</MenuItem>
                    </Select>
                  </FormControl>
                </MDBox>
                <MDBox mb={3}>
                  <MDInput
                    type="number"
                    label="Block Number"
                    variant="standard"
                    fullWidth
                    onChange={(e) => handleInputChange(e, "text")}
                    name="block_number"
                  />
                </MDBox>
                <MDBox mb={3}>
                  <MDInput
                    type="text"
                    label="Flat Number"
                    variant="standard"
                    fullWidth
                    onChange={(e) => handleInputChange(e, "text")}
                    name="flat_number"
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="password"
                    label="Password"
                    variant="standard"
                    fullWidth
                    onChange={(e) => handleInputChange(e, "text")}
                    name="password"
                  />
                </MDBox>
                <MDBox className="upload-container" py={3} px={3}>
                  {passport ? (
                    <MDBox style={{ marginRight: "2px", padding: "5px 2px" }}>
                      <img src={passport} height="120px" width="100px" />
                    </MDBox>
                  ) : null}
                  <MDBox>
                    <MDBox className="upload-holder d-flex flex-column align-items-center">
                      <img src={download} />
                      <MDInput
                        type="file"
                        label="File"
                        variant="standard"
                        fullWidth
                        className="upload-btn"
                        name="img"
                        onChange={(e) => handleInputChange(e, "file")}
                      />
                    </MDBox>
                    <MDBox>
                      <Typography
                        className={
                          passport
                            ? "small-text " + classes
                            : "medium-text " + classes
                        }
                      >
                        Browse File to upload
                      </Typography>
                      <Typography className="small-text black-medium text-center">
                        File Supported: PNG,JPEG,JPG
                      </Typography>
                    </MDBox>
                  </MDBox>
                </MDBox>
                {width ? <LbicButton
                  onClick={createAllotteeAccount}
                  title="Submit"
                  color="success"
                  className='button-center'
                /> : null}
              </MDBox>
            </MDBox>
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
               <Checkbox />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox> */}
            {/* <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth>
                sign in
              </MDButton>
            </MDBox> */}
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/login"
                  variant="button"
                  color="success"
                  fontWeight="medium"
                  textGradient
                >
                  Login
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default (Register)
