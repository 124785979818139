import { Grid, Typography } from "@mui/material";
import MDBox from "../MDBox";
import MoreVertSharpIcon from "@mui/icons-material/MoreVertSharp";
import ApartmentSharpIcon from '@mui/icons-material/ApartmentSharp';

export const PropertyCard = (props) => {
  let {property, callback, key} = props

  return (
    <Grid className="property-shadow" item lg={3.1} sm={12} xs={12} md={5} key={key}
    >
      <div className="w-100 p-4 d-flex flex-row justify-content-between align-items-start">
        <div className="w-75 ">
        <div>
            <ApartmentSharpIcon fontSize="medium" htmlColor="#42B549"/>
          </div>
          <div className="w-100">
            <Typography className="d-flex flex-row justify-content-between align-items-start my-2">
              <span className="text-align-left medium-text light black-light">
                Location.:
              </span>{" "}
              <span className="text-align-right medium-text semi-bold" style={{lineHeight: 1, top: '2px !important'}}>
              {property.location !== null ? property.location : ''}
              </span>
            </Typography>
            <Typography className="d-flex flex-row justify-content-between align-items-start my-2">
              <span className="text-align-left medium-text light black-light">
                Block no.:
              </span>{" "}
              <span className="text-align-right medium-text semi-bold">
              {property.block_number !== null ? property.block_number : ''}
              </span>
            </Typography>
            <Typography className="d-flex flex-row justify-content-between align-items-start my-2">
              <span className="text-align-left medium-text light black-light">
                Flat no.:
              </span>{" "}
              <span className="text-align-right medium-text semi-bold">
                {props.flat_number !== null ? property.flat_number : ''}
                </span>
            </Typography>
          </div>
        </div>
        <div lassName="w-25" onClick={() => callback()}>
          <MoreVertSharpIcon fontSize="medium" />
        </div>
      </div>
    </Grid>
  );
};
