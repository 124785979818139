// dependencies
import { useState } from "react";
// import { useParams } from "react-router";

// @mui material components
import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../components/MDBox";

// css
import "../assets/CSS/index.css";
import { LBInput } from "../components/lbic_component/LBIC_Input";
import MDButton from "../components/MDButton";
import LbicButton from "../components/lbic_component/LbicButton";

function MakePayment() {
  //   const { premuim } = useParams();
  const [billingDetails, setBillingDetails] = useState({});

  console.log(billingDetails);
  return (
    <>
      <MDBox mt={8} className='top'>
        <MDBox mb={3}>
          <Card className="normal-container">
            <CardHeader
              component={Typography}
              title="Payment"
              sx={{ textAlign: "center" }}
            />
            <CardContent className="d-flex flex-column align-items-center">
              <LBInput
                inputType="text"
                variant="outlined"
                width="w-50"
                textType="text"
                label="Owner's Name"
                labelTop={true}
                setText={setBillingDetails}
                text={billingDetails}
                labelName="ownersname"
              />

              <LBInput
                inputType="text"
                variant="outlined"
                width="w-50"
                textType="text"
                label="Property Details"
                labelTop={true}
                setText={setBillingDetails}
                text={billingDetails}
                labelName="propertydetails"
              />

              {/* <Grid container className="w-50 px-sm-0 mx-sm-0" columnSpacing={3} >
                <Grid item sm={12} xm={6} xl={6} className="px-sm-0 mx-sm-0">
                  <LBInput
                    inputType="text"
                    variant="outlined"
                    width="w-100"
                    textType="text"
                    label="Period:From"
                    labelTop={true}
                    setText={setBillingDetails}
                    text={billingDetails}
                    labelName="from"
                  />
                </Grid>
                <Grid item sm={12} xm={6} xl={6}>
                  <LBInput
                    inputType="text"
                    variant="outlined"
                    width="w-100"
                    textType="text"
                    label="To"
                    labelTop={true}
                    setText={setBillingDetails}
                    text={billingDetails}
                    labelName="from"
                  />
                </Grid>
              </Grid> */}

              <LbicButton title='Make Payment' color='success' onClick={() => alert(Object.values(billingDetails))} />
            </CardContent>
          </Card>
        </MDBox>
      </MDBox>
    </>
  );
}

export default MakePayment;
