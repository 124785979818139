import { Box, Container, Typography } from "@mui/material";
import React from "react";

// component
import MDButton from "../MDButton";
import MDTypography from "../MDTypography";

// assets
import Image from "../../assets/building.png";

// CSS Style
import "../../assets/CSS/index.css";
import { Link } from "react-router-dom";

const Header = () => {
  const [tabWidth, setNewWidth] = React.useState(window.outerWidth)

  window.addEventListener("resize",() => setNewWidth(window.outerWidth))

  const LeftSection = () => {
    return(
      <Box className="text-area">
        <Box style={{marginBottom: "3%", padding: "7% 0"}}>
          <h1  className='heading-text mb-sm-2'
          >
            Insurance Premium Payment {tabWidth > 1200 ? <br/> : null} Platform.
          </h1>
          <p className="normal-text">
            A secured platform to insure your property as Owner or Occupier
          </p>
        </Box>
        <MDButton className="btn-register">
          <Link className="link" to='/authentication/register'>
            Register
          </Link>
        </MDButton>
      </Box>
    )
  }

  const TabView = () => {
    return(
      <Box className="tablet-view">
        <LeftSection />
      </Box>
    )
  }


  return (
    <Container className="header">
      {tabWidth === 768 || tabWidth < 768 ?  (<TabView />) :
      (<>
        <LeftSection />
        <Box className="image-area">
          <img src={Image}/>
        </Box>
        </>) }
    </Container>
  );
};

export default Header;
