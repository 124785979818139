// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router-dom components
import { NavLink } from "react-router-dom";

// CSS stylesheet
import '../../../assets/CSS/index.css'

// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";

function DefaultNavbarLink({ icon, name, route, light, ...rest }) {


  return (
    <MDBox
      component={NavLink}
      to={route}
      mx={1}
      p={1}
      display="flex"
      alignItems="center"
      sx={{ cursor: "pointer", userSelect: "none"}}
      className="typography"
      activeClassName="typography_active"
      {...rest}
    >
      <MDTypography
        variant="button"
        fontWeight="light"
        color={light ? "white" : "dark"}
        textTransform="capitalize"
        sx={{ width: "100%", lineHeight: 0, fontSize: 'inherit',fontWeight:'inherit', color: 'inherit'}}>
        {name}
      </MDTypography>
    </MDBox>
  );
}

// Typechecking props for the DefaultNavbarLink
DefaultNavbarLink.propTypes = {
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  light: PropTypes.bool.isRequired,
};

export default DefaultNavbarLink;
