// dependecies
import { FormControl,MenuItem } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
// components
import MDBox from "../MDBox";
import MDInput from "../MDInput";

// utils
import { handleInputChange } from "../utilities/HandleChange";

import '../../assets/CSS/index.css'

export const LBInput = (props) => {
    const {setText,text,inputType,labelTop, textType, options,label, labelName,variant,width} = props
    if(inputType === 'text'){
        return(
        <MDBox mb={3} className={width}>
          {labelTop ? <InputLabel
        id="demo-simple-select-label"
        className="select-label mb-sm-2"
      >
        {label}
      </InputLabel> : null}
      <MDInput
        type={textType}
        label={labelTop ? '' : label}
        variant={variant}
        fullWidth
        onChange={(e) => handleInputChange(e,setText,text)}
        name={labelName}
        className={labelTop ? 'custom-input' : null}
      />
    </MDBox>)
    }else
    {return (<MDBox mb={4}>
    <FormControl variant="standard" fullWidth>
      <InputLabel
        id="demo-simple-select-label"
        className="select-label"
      >
        {label}
      </InputLabel>
      <Select
        label={label}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        className="select-form-control"
        onChange={(e) => handleInputChange(e,setText,text)}
        name={labelName}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {options !== null || undefined ? options.map((item,i) => <MenuItem value={item} key={i}>{item}</MenuItem>): null}
      </Select>
    </FormControl>
  </MDBox>)}
}
