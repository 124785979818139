import * as React from 'react';
import {useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { Navigate } from 'react-router-dom';
import { signOut } from '../../context/allotees/apiClass';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function SessionTimedout({isTimeout}) {
const dispatch = useDispatch();
  const handleSignOut = () => {
    dispatch(signOut())
    return <Navigate to="/authentication/login" />
  };

  return (
    <div>
      <Modal
        open={isTimeout}
        // onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>
          <h2 id="parent-modal-title">Session Expired</h2>
          <p id="parent-modal-description">
          You have been idle for some minutes.
          </p>
        <Button onClick={handleSignOut}>Log in</Button>
        </Box>
      </Modal>
    </div>
  );
}