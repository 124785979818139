import { createStore } from 'redux';
import storage from 'redux-persist/lib/storage';
import {
    persistReducer,
    persistStore,
} from 'redux-persist';
import {reducers} from './RootReducer'

const persistConfig = { key: 'root', storage}

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = createStore(persistedReducer)

export const persistor = persistStore(store)

export default {store , persistor}
