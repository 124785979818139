import React from "react"
import { ClientCall } from "../../context/allotees/apiClass"

export const useWidth = (width) => {
    const currentWidth = window.outerWidth
    window.addEventListener("resize",() => setNewWidth(window.outerWidth))
    const [newWidth, setNewWidth ] = React.useState(width)

    if(currentWidth !== newWidth){
        if(newWidth > width || currentWidth > width){
            return false
        }else{
            return true
        }
    }else return currentWidth < width || currentWidth === width ? true : false
}

export class SessionTimer {
    constructor({timeout, onTimeout,onExpired}){
        this.timeout = timeout;
        this.onTimeout = onTimeout;

        const expiredTime = parseInt(localStorage.getItem("expiredTime") || 0, 10)
        if(expiredTime > 0 && expiredTime < Date.now()){
            onExpired();
            return
        }

        this.eventHandler = this.updateExpiredTime.bind(this)
        this.tracker()
        this.startInterval()
    }

    startInterval(){
        this.updateExpiredTime();

        this.interval = setInterval(() => {
            const expiredTime = parseInt(localStorage.getItem("expiredTime") || 0, 10)
            if (expiredTime < Date.now()){
                if(this.onTimeout){
                    this.onTimeout();
                    this.cleanUp()
                }
            }
        }, 1000)
    }

    updateExpiredTime(){
        if(this.timeoutTracker){
            clearTimeout(this.timeoutTracker)
        }
        this.timeoutTracker = setTimeout(() => {
            localStorage.setItem("expiredTime", Date.now() + this.timeout * 1000)
        }, 300)
    }

    tracker(){
        window.addEventListener("mousemove", this.eventHandler);
        window.addEventListener("scroll", this.eventHandler);
        window.addEventListener("keydown", this.eventHandler);
    }

    cleanUp(){
        localStorage.removeItem("expiredTime")
        clearInterval(this.interval);
        window.removeEventListener("mousemove", this.eventHandler)
        window.removeEventListener("scroll", this.eventHandler)
        window.removeEventListener("keydown", this.eventHandler)
    }
}

export const toggleMenu = () => {
    const el = document.getElementById('id-mobile-menu')
    let classValue = el.getAttribute('class')
    if(classValue === "responsive-menu-off"){
        el.classList.replace(classValue, 'responsive-menu')
    }else el.classList.replace(classValue, 'responsive-menu-off')
}

export const ValidateData = (data,post,endurl,actionType,callback,errcallback,dispatch,userType,setValidation) => {
    if(Object.keys(data).length === 3){
      let checkforEmptyValue = Object.values(data).find(value => value === '')
      if(checkforEmptyValue === ''){
        setValidation(false)
      }else{
        ClientCall(data,post,endurl,actionType,callback,errcallback,dispatch,userType)
      }
    }else{
        setValidation(false)
    }
}

export const ObjectToArray = (data) => {
    let arrdata = []
    for (const key in data) {
      arrdata.push(data[key])
    }

    return arrdata
  }