import { useState } from "react";
import { connect } from "react-redux";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import MDBox from "../components/MDBox";
import MDTypography from "../components/MDTypography";
import MDInput from "../components/MDInput";
import { handleInputChange } from "../components/utilities/HandleChange";

const ClaimReport = ({ allotee, properties }) => {
  const [userAsAllotee, setUserAsAllotee] = useState({ ...allotee});
  const [selectedProperty, setSelectedProperty] = useState({})
  const [location, setLocation] = useState('')
  const [show,setShow] = useState(false);

    const selectProperty = (data) => {
        // const property = properties.find(item => id === item.id)
        setLocation(data.location)
        setSelectedProperty(data)
    }

  const ListProperties = ({item}) => {
    return(
        <MenuItem key={item.id} value={item.location} onClick={() => selectProperty(item.id)}>
            {item.block_number+" in "+item.location}
        </MenuItem>
    )
  }

  console.log(selectedProperty)
  return (
    <div className="normal-container">
      <MDBox py={10} px={3}>
        <MDBox role="form">
          <MDBox mx={2} mb={7} className="column">
            <MDBox className="item">
              <MDBox mb={3}>
                <MDInput
                  type="text"
                  label="Owner's Name"
                  variant="standard"
                  fullWidth
                  onChange={(e) => handleInputChange(e,setUserAsAllotee,userAsAllotee)}
                  name="owners_name"
                  defaultValue={userAsAllotee.owners_name}
                />
              </MDBox>

              {/* <MDBox mb={4}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    className="select-label"
                  >
                    Location
                  </InputLabel>
                  <Select
                    label="Location"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    className="select-form-control"
                    onChange={(e) => handleInputChange(e,setUserAsAllotee,userAsAllotee)}
                    name="location"
                    value={userAsAllotee.location}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {properties.length > 0
                      ? properties.map((item) => (<ListProperties item={item} />))
                      : null}
                  </Select>
                </FormControl>
              </MDBox> */}

              <MDBox mb={4}>
                <p className="small-text" style={{color: "#7b809a"}}>Location</p>
                <div className="btn w-100 rounded-3 small-text" style={{backgroundColor: "#e1dfdf", textAlign: "left"}} onClick={() => !show ? setShow(true) : setShow(false)}>
                  {location !== "" ? location : "Select Location"}
                </div>
                {show?<div className="">
                  <ul>
                    {properties.map(item => (<li onClick={() => selectProperty(item)}>{item.location}</li>))}
                  </ul>
                </div>:null}
              </MDBox>

              {/* <MDBox mb={3}>
                  <MDInput
                    type="text"
                    label="Occupier's Name"
                    variant="standard"
                    fullWidth
                    onChange={(e) => handleInputChange(e,setUserAsAllotee,userAsAllotee)}
                    name="occupiers_name"
                    defaultValue={userAsAllotee.occupiers_name}
                  />
                </MDBox> */}
              {/* <MDBox mb={3}>
                  <MDInput
                    type="email"
                    label="E-mail Address"
                    variant="standard"
                    fullWidth
                    onChange={(e) => handleInputChange(e,setUserAsAllotee,userAsAllotee)}
                    name="email"
                    defaultValue={userAsAllotee.occupiers_name}
                  />
                </MDBox> */}
              {/* <MDBox mb={2}>
                  <MDInput
                    type="phone"
                    label="Phone Number"
                    variant="standard"
                    fullWidth
                    onChange={(e) => handleInputChange(e,setUserAsAllotee,userAsAllotee)}
                    name="phone"
                    defaultValue={userAsAllotee.phone}
                  />
                </MDBox> */}
            </MDBox>
            {/* <MDBox className="item">
                <MDBox mb={3}>
                  <MDInput
                    type="number"
                    label="Block Number"
                    variant="standard"
                    fullWidth
                    onChange={(e) => handleInputChange(e, "text")}
                    name="block_number"
                  />
                </MDBox>
                <MDBox mb={3}>
                  <MDInput
                    type="text"
                    label="Flat Number"
                    variant="standard"
                    fullWidth
                    onChange={(e) => handleInputChange(e, "text")}
                    name="flat_number"
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="password"
                    label="Password"
                    variant="standard"
                    fullWidth
                    onChange={(e) => handleInputChange(e, "text")}
                    name="password"
                  />
                </MDBox>
                <MDBox className="upload-container" py={3} px={3}>
                  {passport ? (
                    <MDBox style={{ marginRight: "2px", padding: "5px 2px" }}>
                      <img src={passport} height="120px" width="100px" />
                    </MDBox>
                  ) : null}
                  <MDBox>
                    <MDBox className="upload-holder d-flex flex-column align-items-center">
                      <img src={download} />
                      <MDInput
                        type="file"
                        label="File"
                        variant="standard"
                        fullWidth
                        className="upload-btn"
                        name="img"
                        onChange={(e) => handleInputChange(e, "file")}
                      />
                    </MDBox>
                    <MDBox>
                      <Typography
                        className={
                          passport
                            ? "small-text " + classes
                            : "medium-text " + classes
                        }
                      >
                        Browse File to upload
                      </Typography>
                      <Typography className="small-text black-medium text-center">
                        File Supported: PNG,JPEG,JPG
                      </Typography>
                    </MDBox>
                  </MDBox>
                </MDBox>
                <LbicButton
                  onClick={createAllotteeAccount}
                  title="Submit"
                  color="success"
                  className='button-center'
                />
              </MDBox> */}
          </MDBox>
        </MDBox>
      </MDBox>
    </div>
  );
};

export default connect((state) => ({
  allotee: state.alloteeReducer.currentUser,
  properties: state.alloteeReducer.properties,
}))(ClaimReport);
