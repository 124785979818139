import { connect } from "react-redux";
import { Container, Grid, Typography } from "@mui/material";
import MDBox from "../components/MDBox";
import { PropertyCard } from "../components/lbic_component/PropertyCard";
import { useEffect } from "react";
import { useState } from "react";
import { ClientCall } from "../context/allotees/apiClass";
import { ObjectToArray } from "../components/utilities/helperFunctions";

const ViewProperties = ({properties}) => {
  // const [property,setProperty] = useState([...properties])

  // const callback = (response) => {
  //   setProperty(response.Transactions)
  // }

  // const errcallback = (err) => {
  //   console.log(err)
  // }

  // useEffect(() => 
  // {
  //   ClientCall(null, 'get','/get-property',null,callback,errcallback,null,'allotee')
  // }
  // ,[])

  const displayMenu = () => {
    console.log("clicked")
  }

  return (
    <div  style={{ marginLeft: 0, padding: 0 }}>
      <MDBox className="w-100 px-auto py-auto">
        <Grid columnGap={"10%"} container={true} lg={12} xl={12} md={12} sm={12} xs={12} rowGap={6}>
          {properties.length > 0 ? properties.map((item,index) => (<PropertyCard key={index} property={item} callback={displayMenu}/>)) : null}
        </Grid>
      </MDBox>
    </div>
  );
};

export default connect((state) => {
  return { currentUser: state.alloteeReducer.currentUser, properties: state.alloteeReducer.properties};
}, null)(ViewProperties);
