import { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";

// react-router-dom components
import { useLocation, NavLink, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";

// Material Dashboard 2 React example components
import SidenavCollapse from "./SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "./SidenavRoot";
import sidenavLogoLabel from "./styles/sidenav";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "../../context/index";

// css
import "../../assets/CSS/index.css";
import { signOut } from "../../context/allotees/apiClass";
import { FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { handleInputChange } from "../../components/utilities/HandleChange";
import MDInput from "../../components/MDInput";
import ModalMessenger from "../../components/lbic_component/Modal";
import Store from "../../context/Store";
import { ValidateData } from "../../components/utilities/helperFunctions";

function Sidenav({ color, brand, brandName, routes, currentUser, ...rest }) {
  const [open, setOpen] = useState(false);
  const [property, setProperty] = useState({});


  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    sidenavColor,
  } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  const dispatchAction = useDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : transparentSidenav
      );
      setWhiteSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : whiteSidenav
      );
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route }) => {
      let returnValue;
      let routeUrl = route.includes(":")
        ? route.concat("=", currentUser.occupiers_name).toLowerCase()
        : route;

      if (type === "collapse") {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <NavLink key={key} to={routeUrl} className="link">
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
            />
          </NavLink>
        );
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      return returnValue;
    }
  );

  const handleModal = () => {
    setSubmenu(false)
    setOpen(true);
  }
  const handleClose = () => {setOpen(false);}

  const [response, setRes] = useState(false)
  const [submenu, setSubmenu] = useState(false)

  const callback = (res) => {
    setProperty({})
    setRes(true)
    setTimeout(()=> setRes(false), 1500)
  }

  const errcallback = (res) => {
    setProperty({})
  }



  const ModalBody = (props) => {

    const handleInput = (e) => {
      const {name,value} = e.target;
      props.setProperty({...props.property, [name]: value})
    }

    return (
      <div className="add-prop-modal mt-3">
        {!response ? <MDBox mb={4}>
          <Typography>Add Property</Typography>
        </MDBox> : null}
        {!response ? <div>
        <MDBox mb={4}>
          <FormControl variant="standard" fullWidth>
            <InputLabel id="demo-simple-select-label" className="select-label">
              Location
            </InputLabel>
            <Select
              label="Location"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className="select-form-control"
              onChange={e => handleInput(e)}
              name="location"
              defaultValue={props.property['location'] !== undefined ? props.property['location'] : '' }
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="meiran">Meiran</MenuItem>
              <MenuItem value="ipaja">Ipaja</MenuItem>
              <MenuItem value="ikorodu">Ikorodu</MenuItem>
              <MenuItem value="surulere">Surulere</MenuItem>
            </Select>
          </FormControl>
        </MDBox>
        <MDBox mb={3}>
          <MDInput
            type="number"
            label="Block Number"
            variant="standard"
            fullWidth
            onBlur={e=>handleInput(e)}
            name="block_number"
            defaultValue={property['block_number']}
          />
        </MDBox>
        <MDBox mb={3}>
          <MDInput
            type="number"
            label="Flat Number"
            variant="standard"
            fullWidth
            onBlur={e=>handleInput(e)}
            name="flat_number"
            defaultValue={property['flat_number']}
          />
        </MDBox>
        </div> : <div className="d-flex justify-content-center pt-5 pb-5">
          <p className="pt-5 pb-5 fs-2 fw-semibold" style={{color: '#42B549'}}>Property Successfully Added</p>
        </div>}
      </div>
    );
  };

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <ModalMessenger
        open={open}
        handleClose={handleClose}
        body={<ModalBody handleInputChange={handleInputChange} property={property} setProperty={setProperty}/>}
        maxWidth="sm"
        fullWidth
        isButton={response ? false : true}
        buttonText="ADD"
        callback={() => ValidateData(property,'post','/add-property','add-property',callback,errcallback,dispatchAction,'allotee')}
        buttonClasses="btn"
      />
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox
          component={MDBox}
          // to="/"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          {/* {brand && <MDBox component="img" src={brand} alt="Brand" width="2rem" />} */}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography
              component="h6"
              variant="button"
              fontWeight="medium"
              color={textColor}
            >
              {brandName}
            </MDTypography>
          </MDBox>
          <div onClick={() => !submenu ? setSubmenu(true) : setSubmenu(false)} style={{cursor: 'pointer'}}>
            {!submenu ? <ExpandMoreRoundedIcon fontSize="medium" color="white" /> : <ExpandLessRoundedIcon fontSize="medium" color="white" />}
          </div>
        </MDBox>
        {submenu ? 
          <div className="d-flex flex-column mt-3">
          <MDButton className='property-menu' onClick={() => handleModal()}><DomainAddIcon fontSize="50" color="white" /> <span className="ms-1">Add Property</span></MDButton>
          <div className="mt-2"></div>
          <MDButton className='property-menu' onClick={() => {
            setSubmenu(false)
            navigate('/view-properties')
            }}>View Properties</MDButton>
          </div> : null
        }
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List onClick={() => {
        if(submenu){
          setSubmenu(false)
        }
      }}>{renderRoutes}</List>
      <MDBox p={2} mt="auto">
        <MDButton
          component="a"
          target="_blank"
          rel="noreferrer"
          variant="gradient"
          color={sidenavColor}
          fullWidth
          onClick={() => {
            dispatchAction(signOut());
          }}
        >
          Log out
        </MDButton>
      </MDBox>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect((state) => ({
  currentUser: state.alloteeReducer.currentUser,
}))(Sidenav);
