// dependencies
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import bcrypt from 'bcryptjs'

// @mui material components
import Card from "@mui/material/Card";
import { LinearProgress } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../components/MDBox";
import MDTypography from "../components/MDTypography";
import MDInput from "../components/MDInput";
import MDButton from "../components/MDButton";

// Authentication layout components
import CoverLayout from "./authentication/components/CoverLayout";

// api class
import { ClientCall } from "../context/allotees/apiClass";

// Images
import bgImage from "../assets/house.png";

// css rules
import "../../src/assets/CSS/index.css";
import { useState } from "react";

// utilities
import { handleInputChange } from "../components/utilities/HandleChange";
import { MessageDisplay } from "../components/lbic_component/Message";

function SignIn() {
  const [credentials, setCredentials] = useState({});
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false)
  const [errMessage, setMessage] = useState('')
  const dispatch = useDispatch();
  const navigate = useNavigate()

  
  // let hashPassword
  // if(credentials.password !== undefined){
  //   hashPassword = bcrypt.hashSync(credentials.password,10)
  // }

  const submitCredentials = () => {
    setLoading(true)
    setErr(false)
    ClientCall(
      credentials,
      "post",
      "/login",
      "login",
      callback,
      errCallback,
      dispatch,
      null
    );
  };

  const callback = () => {
    navigate('/dashboard')
    setLoading(false)
  };

  const errCallback = (response) => {
    setLoading(false)
    setErr(true)
    if(response.response.status === 422){
      setMessage(response.response.data.error)
    }if(response.response.status === 401 || 500){
      setMessage("User does not exist in our system");
    }
  };
  return (
    <CoverLayout
      image={bgImage}
      urlText="Register"
      link="/authentication/register"
    >
      <Card className="w-25 sign-in">
        <MDBox py={5} px={3} >
          {loading ? <LinearProgress
            color="success"
            style={{ overflow: "hidden", top: "-20px" }}
          /> : null}
          <MessageDisplay className='small-text black-deep' open={err} message={errMessage} />
          <MDBox role="form">
            {/* <MDBox mx={2} mb={7} className="column"> */}
            {/* <MDBox className="item"> */}
            <MDBox mb={3}>
              <MDInput
                type="email"
                label="E-mail Address"
                variant="standard"
                fullWidth
                onChange={(e) =>
                  handleInputChange(e, setCredentials, credentials)
                }
                name="email"
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                variant="standard"
                name="password"
                onChange={(e) =>
                  handleInputChange(e, setCredentials, credentials)
                }
                fullWidth
              />
            </MDBox>
            <MDBox mt={5} className="d-flex flex-column align-items-center">
              <MDButton variant="gradient" color="success" onClick={submitCredentials}>
                Log in
              </MDButton>
            </MDBox>
            {/* </MDBox> */}
            {/* </MDBox> */}
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don't have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/register"
                  variant="button"
                  color="success"
                  fontWeight="medium"
                  textGradient
                >
                  Register
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default SignIn;
