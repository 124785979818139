// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "../components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "../examples/LayoutContainers/DashboardLayout";
import { connect, useDispatch } from "react-redux";
import MDInput from "../components/MDInput";
import { FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import LbicButton from "../components/lbic_component/LbicButton";
import { Link, Navigate } from "react-router-dom";
import { useEffect } from "react";
import { ClientCall } from "../context/allotees/apiClass";
import { useState } from "react";

function AllotteeDashboard({ currentUser,properties }) {
  const dispatch = useDispatch()
  const [alloteeProperties,setAlloteeProperties] = useState([...properties])


  const callback = (response) => {
    console.log('successful')
  }

  const errcallback = (e) => {
    console.log(e)
  }
  
  useEffect(()=>{
    ClientCall(null,'get','/get-property','add-property',callback,errcallback,dispatch,'allotee',alloteeProperties.length)
  },[])
  return (
      <MDBox py={3}>
        <div className="dashboard-heading"><Typography>Your Profile Details</Typography></div>
        <p></p>
        <div className="information-container">
          <MDBox mb={5} style={{width: '100%'}}>
            <MDInput
              type="text"
              label="Owner's Name"
              variant="standard"
              fullWidth
              name="owners_name"
              defaultValue={currentUser.owners_name}
              disabled="true"
            />
          </MDBox>

          <MDBox mb={5} style={{width: '100%'}}>
            <MDInput
              type="text"
              label="Occupier's Name"
              variant="standard"
              fullWidth
              name="owners_name"
              defaultValue={currentUser.occupiers_name}
              disabled="true"
            />
          </MDBox>

          <MDBox mb={5} style={{width: '100%'}}>
            <MDInput
              type="text"
              label="Phone Number"
              variant="standard"
              fullWidth
              name="owners_name"
              defaultValue={currentUser.phone}
              disabled="true"
            />
          </MDBox>

                <MDBox mb={5}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel
                      id="demo-simple-select-label"
                      className="select-label"
                    >
                      Location
                    </InputLabel>
                    <Select
                      label="Location"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className="select-form-control"
                      name="location"
                      value={currentUser.location}
                      disabled
                    >
                      <MenuItem value={currentUser.location}>
                        {currentUser.location.toUpperCase().slice(0,1)+currentUser.location.slice(1)}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </MDBox>

                <MDBox mb={5}>
                  <MDInput
                    type="number"
                    label="Block Number"
                    variant="standard"
                    fullWidth
                    name="block_number"
                    defaultValue={currentUser.block_number}
                    disabled="true"
                  />
                </MDBox>

                <MDBox >
                  <MDInput
                    type="number"
                    label="Flat Number"
                    variant="standard"
                    fullWidth
                    name="flat_number"
                    defaultValue={currentUser.flat_number}
                    disabled="true"
                  />
                </MDBox>
        </div>
        <div className="dashboard-heading">
          <Link className="btn-edit-profile mt-5 btn" to={`/name=${currentUser.occupiers_name}`}>Edit Profile</Link>
          </div>
        {/* 
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Followers"
                count="+91"
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
        </Grid> */}
        {/* <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="website views"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="daily sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox> */}
        {/* <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Projects />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid>
          </Grid>
        </MDBox> */}
      </MDBox>
  );
}

export default connect((state) => {
  return { currentUser: state.alloteeReducer.currentUser, properties: state.alloteeReducer.properties};
}, null)(AllotteeDashboard);
