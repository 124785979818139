import * as React from 'react'

// components
import MenuIcon from '@mui/icons-material/Menu';

// utility function for mobile menu
import { toggleMenu } from '../utilities/helperFunctions';



const DashboardMobileMenu = () => {

    return(
        <div className='mobile-menu'>
            <span onClick={toggleMenu}>
                <MenuIcon fontSize='medium' color='black'/>
            </span>
        </div>
    )
}

export default DashboardMobileMenu