import React from "react";
import { connect } from "react-redux";
import html2canvas from 'html2canvas'
import jspdf from 'jspdf'

import PrintIcon from '@mui/icons-material/Print';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';

// css
import '../assets/CSS/index.css'
import LbicButton from "../components/lbic_component/LbicButton";
import moment from "moment";


const Invoice = ({currentUser,payment}) => {

  console.log(payment)

  return (
    <div className="print-container" style={{height: '90%'}}>
      <div className="row" style={{height: '100%'}}>
        <div className="col-xs-12" style={{position: 'relative'}}>
          <div className="grid invoice">
            <div className="grid-body reciept" id="print">
            <div><h3 style={{color: 'green' ,textAlign: 'center'}}><strong>Invoice</strong></h3></div>
              <div className="mt-md-5 mt-lg-5 mt-sm-5">
                <div className="d-flex flex-row justify-content-start">
                <div className="col-xs-6 text-right">
                  <address>
                    <strong>Company Address:</strong>
                    <br />
                    Suite 6 Block B, Alausa Shopping Mall,
                    <br />
                    131, Obafemi Awolowo Way,
                    <br />
                    Ikeja, Lagos
                    <br />
                  </address>
                </div>
              </div>
              </div>
              <hr />
              <div className="d-flex flex-row justify-content-between">
                <div className="col-xs-6">
                  <address>
                    <strong>Billed to:</strong>
                    <br />
                    {currentUser.owners_name}
                    <br />
                    {currentUser.block_number} {currentUser.location},
                    Lagos,
                    <br />
                    {currentUser.phone}
                  </address>
                </div>
                <div className="col-xs-6 text-right">
                  <div>
                    <strong>Transaction Ref.:</strong>
                    <br />
                    <strong>Reference Number:</strong>{payment.reference}
                    <br />
                    <strong>Invoice Date:</strong>{moment(payment.start_date).format("Do MMMM, YYYY")}
                    <br />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-between mt-md-3 mt-lg-3 mt-sm-3">
                  <h3>Bill Summary</h3>
                <div className="col-md-12 table-responsive mt-md-3 mt-lg-3 mt-sm-3">
                  <table className="table table-striped ">
                    <thead>
                      <tr className="line">
                        <td>
                          <strong>COVER PLAN</strong>
                        </td>
                        <td className="text-center">
                          <strong>DURATION</strong>
                        </td>
                        <td className="text-center">
                          <strong>PAYMENT</strong>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {/* COVER<br/> */}
                          {payment.coverFlat}
                        </td>
                        <td className="text-center">{moment(payment.expire_date).diff(payment.start_date,'months')} Months</td>
                        <td className="text-center"><img width={12} height={12} src={require('../assets/images/naira.png')} /> 250,000</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              
            </div>
          </div>

          <div className="row justify-content-between" id="invoice-footer">
                <div className="col-md-3 text-right identity download_section">
                <LbicButton
                  onClick={() => {
                    const pageHTML = document.querySelector('.reciept');
                    html2canvas(pageHTML).then((res) => {
                        const imgData = res.toDataURL('image/png')
                        const pdf = new jspdf('p', 'pt', 'a4', false)
                        pdf.addImage(imgData, 'PNG', 10, 80, 575, 0, undefined, false)
                        pdf.save("receipt")
                    })
                  }}
                  icon={<SystemUpdateAltIcon sx={{ marginRight: 1 }}/>}
                  title="Download"
                  color="success"
                  className='button-center'
                />

                
              </div>
              <div className="col-md-3 text-right identity reciept_section">
                <LbicButton
                onClick={() => {
                    window.print()
                }}
                icon={<PrintIcon sx={{ marginRight: 1 }}/>}
                  title="Print Reciept"
                  color="success"
                  className='button-center'
                />
              </div>

                
              </div>
        </div>
      </div>
    </div>
  );
};

export default connect((state) => {
    return { currentUser: state.alloteeReducer.currentUser };
  })(Invoice)
