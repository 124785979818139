// dependencies
import { Link } from "react-router-dom";

// components
import MDBox from "../MDBox";
import MDButton from "../MDButton";

function LbicButton(props) {
  return (
    <MDBox mt={5} {...props}>
      <MDButton variant="gradient" color={props.color}>
        {props.icon}
        {props.title}
      </MDButton>
    </MDBox>
  );
}

export default LbicButton;
